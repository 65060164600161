import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import * as json from '../../../assets/mock/products.json';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.less']
})
export class ProductComponent implements OnInit, OnDestroy {
  public sizes = [];
  public products = [];
  public menuHide = {};
  public showFilter = false;
  public filteredProducts = [];
  public viewProduct = "images";
  public form = {
    products: {
      name: 'products',
      list: {},
      link: ''
    },
    sizes: {
      name: 'sizes',
      list: {},
      link: ''
    }
  };
  public env = environment;
  constructor(private renderer: Renderer2, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe( queryParams => {
      this.env.lang = this.env.allLang.indexOf(queryParams.lg) > -1 ? queryParams.lg : 'en';
      this.env.queryParams = this.env.lang === 'en' ?  null : this.env.lang;
    });

    this.products = JSON.parse(JSON.stringify(json.products));
    this.products.forEach(index => {
      this.form['products'].list[index.name] = false;
      index['catalog'].forEach(item => {
        item.size && item.size.forEach( sizeItem => {
          if (this.sizes.indexOf(sizeItem) === -1) {
            this.sizes.push(sizeItem);
            this.form['sizes'].list[sizeItem] = false;
          }
        });
      });
    });

    let subscribeUrl = this.route.params.subscribe(params => {
      console.log('router');
      let productList = [];
      let paramsArrayId = params.id ? params.id.split(',') : [];
      params.view && (this.viewProduct = params.view);
      paramsArrayId.length && this.products.forEach(index => {
        paramsArrayId.forEach( id => {
          if (index.link === id) {
            productList.push(index.name);
            this.form['products'].list[index.name] = true;
          }
        });
      });
      if (productList.length) {
        this.menuHide['products'] = true;
      }
      this.getProducts();
    }).unsubscribe();
  }

  showFilterFunc(show) {
    if (show) {
      this.renderer.addClass(document.body, 'modal-open');
      this.showFilter = true;
    } else {
      this.renderer.removeClass(document.body, 'modal-open');
      this.showFilter = false;
    }
  }
  getElementsFromObjects(item) {
    return Object.keys(item);
  }
  getLinkByName(name) {
    let link = '';
    this.products.forEach(index => {
      if (index.name === name) {
        link = index.link;
      }
    });
    return link;
  }
  getPoductName(name) {
    let prodName = '';
    this.products.forEach(index => {
      if (index.name === name) {
        prodName = this.env.getLanguage(index.translate).description;
      }
    });
    return prodName;
  }

  changeUrl(){
    let params = {};
    (this.viewProduct!== 'images') && (params['view'] = this.viewProduct);
    (this.form['products'].link) && (params['id'] = this.form['products'].link);
    this.router.navigate(['/products', params], {queryParams: {lg: this.env.queryParams}});
  }
  // отфильтрованный список продуктов
  getProducts() {
    this.filteredProducts = [];
    this.form['products'].link ='';
    Object.keys(this.form['products'].list).forEach(key => {
      if (this.form['products'].list[key]) {
        this.form['products'].link = !this.form['products'].link ? this.getLinkByName(key) : this.form['products'].link + ',' + this.getLinkByName(key);
      }
    });
    let selectedSizes = false;
    Object.keys(this.form['sizes'].list).forEach(key => {
      if (this.form['sizes'].list[key]) {
        selectedSizes = true;
      }
    });
    this.products.forEach(index => {
      if (!this.form['products'].link || this.form['products'].list[index.name]) {
        index['catalog'].forEach(index2 => {
          let haveSize = false;
          index2.size && index2.size.forEach(sizes => {
            if (this.form['sizes'].list[sizes]) {
              haveSize = true;
            }
          });
          if (index2.col === 0) {} else if (!selectedSizes || haveSize) {
            this.filteredProducts.push(index2);
          }
        });
      }
    });
    this.changeUrl();
  }

  getSelectedProduct(name) {
    let selectedProduct = false;
    let showBlock = false;
    Object.keys(this.form['products'].list).forEach(key => {
      if (this.form['products'].list[key]) {
        if (key === name) {
          showBlock = true;
        }
        selectedProduct = true;
      }
    });
    return showBlock || !selectedProduct;
  }

  // Очищаем все фильтры
  clearFilters() {
    Object.keys(this.form).forEach(key => {
      Object.keys(this.form[key].list).forEach(key2 => {
        this.form[key].list[key2] = false;
      });
    });
    this.getProducts();
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'modal-open');
  }
}
